import React, { FC, useEffect, useState } from "react";
import styled from "styled-components";
import { useNavigation, useUser } from "../../../../../../hooks";
import { PageQALabels } from "../../../../../../page-objects/components/page/qa-labels";
import { availableRoutes } from "../../../../../../router/routes";
import { useAppDispatch } from "../../../../../../store";
import { Cart } from "../../../../../../store/cart/thunks";
import { ImpersonateBanner } from "../../../../../impersonate-banner";
import MiniCartToggle from "../mini-cart-toggle";
import { AccountLinks } from "./components/account-links";
import { HelpLinks } from "./components/help-links";
import { MainLinks } from "./components/main-links";
// eslint-disable-next-line
const BeckerLogo = require("../../../../../../assets/logo.svg");

export const DesktopHeader: FC = () => {
  const { main, helpLinks } = useNavigation();
  const { user } = useUser();
  const [referenceElement, setReferenceElement] = useState<HTMLElement | null>(null); // Popper's ref for mini cart
  const dispatch = useAppDispatch();
  useEffect(() => {
    user.shoppingCart && dispatch(Cart());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user.shoppingCart]);
  return (
    <HeaderContainer>
      <ImpersonateBanner />
      <StyledHeader data-qa-label={PageQALabels.header}>
        <Nav data-qa-label={PageQALabels.primaryNavigation}>
          <StyledLink href={availableRoutes.default}>
            <StyledLogo />
          </StyledLink>
          <MainLinks items={main || []} />
          {helpLinks.length > 0 && <HelpLinks items={helpLinks} />}
          {user.shoppingCart && (
            <MiniCartToggle setReferenceElement={setReferenceElement} referenceElement={referenceElement} />
          )}
          <AccountLinks />
        </Nav>
      </StyledHeader>
    </HeaderContainer>
  );
};

const HeaderContainer = styled.div`
  position: sticky;
  top: 0;
  z-index: 1000;
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
`;

const StyledLink = styled.a`
  display: flex;
  align-items: center;
  flex-shrink: 0;
`;

const Nav = styled.nav`
  display: flex;
  align-items: center;
  max-width: ${props => props.theme.breakpoints.desktopHD}px;
  padding: 0;
  margin: 0;
  width: 100%;
  height: 100%;
  gap: 32px;
  flex-wrap: nowrap;
`;

const StyledHeader = styled.header`
  height: 70px;
  background-color: ${props => props.theme.colors?.white};
  box-shadow: 0 0 15px 0 rgb(0 0 0 / 15%);

  display: flex;
  align-items: center;
  justify-content: center;

  padding: 0 32px;
`;

const StyledLogo = styled(BeckerLogo)`
  max-height: 100%;
  max-width: 150px;
  height: 34px;
  flex-shrink: 0;
`;
